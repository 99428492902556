<template> 
    <div class="page-content header-clear-small">
        
        <div class="calendar bg-theme shadow-xl rounded-m">
            <div class="cal-header">
                <h4 class="cal-title text-center text-uppercase font-800 bg-green-dark-sdw color-white text-capitalize">{{monthName}} {{year}}</h4>
                <h6 @click.prevent="prev" class="cal-title-left color-white"><i class="fa fa-chevron-left"></i></h6>
                <h6 @click.prevent="next" class="cal-title-right color-white"><i class="fa fa-chevron-right"></i></h6>
            </div>
            <div class="clearfix"></div>
            <div class="cal-days bg-orange-dark-sdw opacity-100 bottom-0">
                <a href="#">L</a>
                <a href="#">M</a>
                <a href="#">M</a>
                <a href="#">J</a>
                <a href="#">V</a>
                <a href="#">S</a>
                <a href="#">D</a>
                <div class="clearfix"></div>
            </div>
            <div class="cal-dates cal-dates-border">
                
				<a v-for="index in daysBefore" :key="index+1000" href="#" class="cal-disabled">-</a>
				
				<a href="#" v-for="index in nbdays" :key="index" 
				v-bind:class="[isRed(index) ? 'cal-selected' : '']"
				@click.prevent="showEvent(index)"
				>
				<i v-if="isRed(index)" class="fa fa-square color-red-dark-sdw"></i><span>{{index}}</span>
				
				
				</a>
				<a v-for="index in daysAfter" :key="index+2000" href="#" class="cal-disabled">-</a>
               
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="decoration decoration-margins"></div>
		<!-- event list -->	
		<!-- event list v2 -->
		<div v-if="events && (events.length > 0)">
		<div class="card card-style" v-for="event in events" v-bind:key="event.id">
			<div class="content text-center mb-2" v-if="(clickedDate == '') || ((clickedDate != '') && ((event.from_date == clickedDate) || (event.to_date == clickedDate)))">
				<img v-if="event.image_url" :src="event.image_url" class="img-fluid rounded-m mb-n3" style="width:100%">
				<img v-else :src="event.entity.background_url" class="img-fluid rounded-m mb-n3" style="width:100%">
				<p class="mb-n2 font-11 badge bg-red2-dark text-uppercase">{{ event.entity.name}} </p>
				<h4 class="pt-2 mb-n1 font-18 pl-2 text-left pt-2">{{event.name}}</h4>
				<p class="mb-0 font-11 opacity-90 pl-2 text-left pt-2"><i class="fa fa-clock pr-2"></i> Du {{ event.from_date | moment('DD/MM/YYYY')}} {{event.from_time}} - Au {{ event.to_date | moment('DD/MM/YYYY')}} {{event.to_time}}</p>
				<p class="mb-0 font-11 opacity-90 pl-2 text-left pt-0"><i class="fa fa-map-marker-alt pr-2"></i> {{ event.place}}</p>
                <p class="mb-4 pl-3 text-left pt-0 font-14" v-html="event.description"></p>	
			</div>
		</div>	
		</div>
		<!-- event list -->
		<Footer></Footer>	
    </div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Calendar',
  data() {
    return{
		month :'',
		monthName:'',
		year:'',
		nbdays : 0,
		heute:0,
		eventDate:'',
		eventFromTime:'',
		eventToTime:'',
		eventName:'',
		eventDescription:'',
		eventPlace:'',
		showevent : false,
		daysBefore:[],
		daysAfter:[],
		events: [],
		clickedDate:''
	}
  },
  components: {
    Footer
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    isloggedin: {
      type: Number,
      default: 5
    }	
  },   
  mounted() {
		this.getEntity()
		this.alert()
		this.now()
  },  
  methods: {
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	truncte (text, stop, clamp) {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
	},	
	next(){
		const monthNames = this.monthNames();
		//from 0 to 11
		if (this.month == 11) {
			this.month = 0;
			this.year = this.year + 1
		} else {
			this.month = this.month + 1
		}
		this.monthName = monthNames[this.month];
		this.nbdays = this.getDaysInMonth(this.month+1, this.year)
		this.getFirsDayOfMonth (this.year, this.month)
		this.getLastDayOfMonth (this.year, this.month)	
	
	},
	prev() {
		const monthNames = this.monthNames();
		//from 0 to 11
		if (this.month == 0) {
			this.month = 11;
			this.year = this.year - 1
		} else {
			this.month = this.month - 1
		}
		this.monthName = monthNames[this.month];
		this.nbdays = this.getDaysInMonth(this.month+1, this.year)
		this.getFirsDayOfMonth (this.year, this.month)
		this.getLastDayOfMonth (this.year, this.month)	
	
	},
	now() {
		
	
	
	
		this.initCalendar()
	},
	showEvent(index) {
		this.clickedDate = this.getSqlDate(index)
/*
		var sqlDate =  this.getSqlDate(index)
			
		if (this.events) {
		var arrayLength = this.events.length;
		for (var i = 0; i < arrayLength; i++) {
				if (this.events[i]['from_date'] == sqlDate)  {
						const d = new Date(this.events[i]['from_date']);
						let n = d.getDate()
						if (n<10) n = '0'+n
						let m = d.getMonth()+1
						if (m<10) m = '0'+m
						if (this.events[i]['to_date']) {
							this.eventDate = 'Du ';
						}	else	{
							this.eventDate='';
						}
						this.eventDate = this.eventDate+ n +  '/' + m + '/' + d.getFullYear()
						if (this.events[i]['from_time']) {
							this.eventDate = this.eventDate + ' '+ this.events[i]['from_time']
						}						
						if (this.events[i]['to_date']) {
						const d = new Date(this.events[i]['to_date']);
						let n = d.getDate()
						if (n<10) n = '0'+n
						let m = d.getMonth()+1
						if (m<10) m = '0'+m
						this.eventDate = this.eventDate+ '<br />Au '+n +  '/' + m + '/' + d.getFullYear()
						}

						if (this.events[i]['to_time']) {
							this.eventDate = this.eventDate + ' '+ this.events[i]['to_time']
						}


						this.eventFromTime = this.events[i]['from_time'];
						this.eventToTime  = this.events[i]['to_time'];
						this.eventName  = this.events[i]['name'];
						this.eventDescription  = this.events[i]['description'];
						this.eventPlace  = this.events[i]['place'];
				}
			}
		}
		*/
		this.showevent = true
	
	},
	monthNames() {
		const monthNames = ["janvier","février","mars","avril","mai","juin","juillet","août","septembre", "octobre", "novembre","décembre"];
		return monthNames;
	},
	initCalendar() {
		const monthNames = this.monthNames();
		const d = new Date();
		this.month = d.getMonth();
		this.monthName = monthNames[this.month];
		this.year = d.getFullYear()
		this.nbdays = this.getDaysInMonth(this.month+1, this.year)
		this.heute = d.getDate() //Return the day of the month from 1 to 31
		this.getFirsDayOfMonth (this.year, this.month)
		this.getLastDayOfMonth (this.year, this.month)
	},
	getDaysInMonth(month,year) {
		// Here January is 1 based
		//Day 0 is the last day in the previous month
		return new Date(year, month, 0).getDate();
		// Here January is 0 based
		// return new Date(year, month+1, 0).getDate();
	},
	getFirsDayOfMonth (year, month) {
		this.daysBefore=[];
		const dayNames = ["D","L","M","M","J","V","S"];
		var firstDayNb = new Date(year, month, 1).getDay();
			// de 0 a 6 en commencant par dimanche
		var firstDay = dayNames[firstDayNb]
		console.log(firstDay)
		for (var i=0; i<firstDayNb-1; i++) {
			this.daysBefore.push(i)
		}
	},
	getLastDayOfMonth (year, month) {
		this.daysAfter=[]
		var lastDayNb = new Date(year, month + 1, 0).getDay();
		
			// de 0 a 6 en commencant par dimanche
		for (var i=0; i<=6; i++) {
			if (i>=lastDayNb)
			this.daysAfter.push(i)
		}
	},	
	getSqlDate(d){
		
		if (d<10) d = '0'+d;
		var m = this.month +1;
		if (m < 10) m = '0'+m;
		return this.year+'-'+m+'-'+d
	
	},
	isRed(d) {
		// d = the day of the month from 1 to 31
		var sqlDate =  this.getSqlDate(d)
		
		var found = false
		
		if (this.events) {
		var arrayLength = this.events.length;
		for (var i = 0; i < arrayLength; i++) {
				if (this.events[i]['from_date'] == sqlDate)  {
								found = true
				}
		}
		}
		return found
		//if (d == this.heute) return true
	},
	alert() {
		this.$emit('alert', this.id)
	},
	getEntity() {
				this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-events"				
				
                if(this.id != 0) {
                    var apiData = { 
					"entity": this.id,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.events = response.data.data
						this.loading = false
					}
				})
				.catch(error => {
				console.log(error.message);
				})	
				} 
	},		
  }
}


</script>
